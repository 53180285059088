<template>
  <div>
    <b-card
      v-if="userDetails.grade_set_id === 1 || userDetails.grade_set_id === 2"
      no-body
      class="saj-title mr-1"
      style=""
    >
      <topManagement /></b-card>
    <b-card
      v-if="userDetails.grade_set_id === 3"
      no-body
      class="saj-title mr-1"
      style=""
    >
      <middleManagement /></b-card>
    <b-card
      v-if="userDetails.grade_set_id === 4"
      no-body
      class="saj-title mr-1"
      style=""
    >
      <executive />
    </b-card>
    <b-card
      v-if="userDetails.grade_set_id === 5"
      no-body
      class="saj-title mr-1"
      style=""
    >
      <nonExecSupport />
    </b-card>
    <b-card
      v-if="userDetails.grade_set_id === 6"
      no-body
      class="saj-title mr-1"
      style=""
    >
      <nonExecSupervisory />
    </b-card>
  </div>
</template>

<style scoped>
.hoverIcon{
  color: purple;
  cursor: pointer;
}

.hoverIcon:hover{
  color: red;
}
</style>

<script>
import Ripple from "vue-ripple-directive"
import {
  BCard,
  VBPopover,
  BPopover,
  VBTooltip,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'
import middleManagement from '@/views/pages/performance/my-performance/my-performance-MiddleManagement.vue'
import executive from '@/views/pages/performance/my-performance/my-performance-Executive.vue'
import nonExecSupport from '@/views/pages/performance/my-performance/my-performance-nonExecSupport.vue'
import nonExecSupervisory from '@/views/pages/performance/my-performance/my-performance-nonExecSupervisory.vue'
import topManagement from '@/views/pages/performance/my-performance/my-performance-Top_Management.vue'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
    topManagement,
    middleManagement,
    executive,
    nonExecSupport,
    nonExecSupervisory,
  },
  directives: {
    "b-popover": VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['userDetails']),
  },

  watch: {

  },
  mounted() {

  },
  methods: {

    afterFilter(newVal) {
      this.filteredItemLengthBTable = newVal
    },

  },

}
</script>
<style>

</style>
